var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FORMAT_YYYY_MM_DD } from '../../../constant/date';
import { fDate, splitIntervalIntoHours } from '../../../utils';
import axios from '../../../utils/axios';
export var getMyCalendarWorkTime = createAsyncThunk('workTimes/GETALL', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, startDate, endDate, response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                startDate = payload.startDate, endDate = payload.endDate;
                return [4 /*yield*/, axios.get('/worktimes/my-worktime', {
                        params: {
                            'startDate[gte]': fDate(startDate, FORMAT_YYYY_MM_DD),
                            'endDate[lt]': fDate(endDate, FORMAT_YYYY_MM_DD),
                        },
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 3:
                err_1 = _a.sent();
                return [2 /*return*/, Promise.reject(err_1.message ? err_1.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var getSingleWorktime = createAsyncThunk('workTimes/single', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, id, response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                id = payload.id;
                return [4 /*yield*/, axios.get("/worktimes/".concat(id))];
            case 1:
                response = _a.sent();
                data = response === null || response === void 0 ? void 0 : response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 2:
                err_2 = _a.sent();
                return [2 /*return*/, Promise.reject(err_2.message ? err_2.message : data === null || data === void 0 ? void 0 : data.message)];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var createCalendarWorkTime = createAsyncThunk('workTimes/create', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, startDate, endDate, title, type, splitHours, response, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                startDate = payload.startDate, endDate = payload.endDate, title = payload.title, type = payload.type;
                splitHours = splitIntervalIntoHours({ startDate: startDate, endDate: endDate, title: title, type: type });
                return [4 /*yield*/, axios.post('/worktimes', {
                        data: splitHours,
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (response === null || response === void 0 ? void 0 : response.data)];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(data);
            case 3:
                err_3 = _a.sent();
                return [2 /*return*/, Promise.reject(err_3.message ? err_3.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var updateCalendarWorkTime = createAsyncThunk('workTimes/update', function (_a) {
    var id = _a.id, body = _a.body;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, response, err_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, axios.patch("/worktimes/".concat(id), body)];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.data];
                case 2:
                    data = _b.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, data];
                    }
                    throw new Error(data);
                case 3:
                    err_4 = _b.sent();
                    return [2 /*return*/, Promise.reject(err_4.message ? err_4 : data === null || data === void 0 ? void 0 : data.message)];
                case 4: return [2 /*return*/];
            }
        });
    });
});
export var deleteCalendarWorkTime = createAsyncThunk('workTimes/delete', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var id, data, response, err_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                id = payload.id;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, axios.delete("/worktimes/", {
                        data: {
                            data: [id],
                        },
                    })];
            case 2:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 3:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 4:
                err_5 = _a.sent();
                return [2 /*return*/, Promise.reject(err_5.message ? err_5.message : data === null || data === void 0 ? void 0 : data.message)];
            case 5: return [2 /*return*/];
        }
    });
}); });
// USER WORKTIMES
export var getUserWorktime = createAsyncThunk('/user-worktimes-get', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, id, startDate, endDate, response, err_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                id = payload.id, startDate = payload.startDate, endDate = payload.endDate;
                return [4 /*yield*/, axios.get("/worktimes/employees/".concat(id), {
                        params: {
                            'startDate[gte]': fDate(startDate, FORMAT_YYYY_MM_DD),
                            'endDate[lt]': fDate(endDate, FORMAT_YYYY_MM_DD),
                        },
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 3:
                err_6 = _a.sent();
                return [2 /*return*/, Promise.reject(err_6.message ? err_6.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var createUserWortime = createAsyncThunk('/user-wortimes-create', function (_a) {
    var userId = _a.userId, body = _a.body;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, startDate, endDate, title, type, splitHours, response, err_7;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    startDate = body.startDate, endDate = body.endDate, title = body.title, type = body.type;
                    splitHours = splitIntervalIntoHours({ startDate: startDate, endDate: endDate, title: title, type: type });
                    return [4 /*yield*/, axios.post("/worktimes/employees/".concat(userId), {
                            data: splitHours,
                        })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, (response === null || response === void 0 ? void 0 : response.data)];
                case 2:
                    data = _b.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, data];
                    }
                    throw new Error(data);
                case 3:
                    err_7 = _b.sent();
                    return [2 /*return*/, Promise.reject(err_7.message ? err_7.message : data === null || data === void 0 ? void 0 : data.message)];
                case 4: return [2 /*return*/];
            }
        });
    });
});
export var updateUserWorktime = createAsyncThunk('/user-worktimes-update', function (_a) {
    var userId = _a.userId, id = _a.id, body = _a.body;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, response, err_8;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, axios.patch("/worktimes/employees/".concat(userId, "/").concat(id), body)];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.data];
                case 2:
                    data = _b.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, data];
                    }
                    throw new Error(data);
                case 3:
                    err_8 = _b.sent();
                    return [2 /*return*/, Promise.reject(err_8.message ? err_8 : data === null || data === void 0 ? void 0 : data.message)];
                case 4: return [2 /*return*/];
            }
        });
    });
});
export var deleteUserWorktime = createAsyncThunk('/user-worktimes-delete', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var id, userId, data, response, err_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                id = payload.id, userId = payload.userId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, axios.delete("/worktimes/employees/".concat(userId), {
                        data: {
                            data: [id],
                        },
                    })];
            case 2:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 3:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 4:
                err_9 = _a.sent();
                return [2 /*return*/, Promise.reject(err_9.message ? err_9.message : data === null || data === void 0 ? void 0 : data.message)];
            case 5: return [2 /*return*/];
        }
    });
}); });
export var checkCalendar = function () { return __awaiter(void 0, void 0, void 0, function () {
    var data, response, err_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, axios.get('/check-calandar')];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_10 = _a.sent();
                return [2 /*return*/, Promise.reject(err_10.message ? err_10.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); };
